import { toast } from "react-toastify";


export const toastErrorSocket = err => {

	if (err) {
		console.log(JSON.stringify(err));

		toast.dismiss();
		toast.error(JSON.stringify(err), {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 10000
		});
		
	}
};


export const toastErrorFixed = (message) => {

	toast.dismiss();
	toast.info(message, {
		position: "top-right",
		autoClose: false,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		});
};


