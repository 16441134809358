import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../services/api";
import Lightbox from "./Lightbox";
import "react-awesome-lightbox/build/style.css";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 250,
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));

const ModalImageCors = ({ imageUrl }) => {

	const classes = useStyles();
	const [fetching, setFetching] = useState(true);
	const [blobUrl, setBlobUrl] = useState("");
	const [visibleImage, setVisibleImage] = useState(false);


	useEffect(() => {

		let isMounted = true;
		if (!imageUrl) return;

		const fetchImage = async () => {

			const { data, headers } = await api.get(imageUrl, {
				responseType: "blob",
			});

			const url = window.URL.createObjectURL(
				new Blob([data], { type: headers["content-type"] })
			);
			if(isMounted) {
				setBlobUrl(url);
				setFetching(false);
			}

		};
		
		fetchImage();
		return () => {isMounted = false;}

	}, [imageUrl]);



	return (
		<div>
			<img
				style={{ cursor: "pointer" }}
				className={classes.messageMedia}
				src={fetching ? imageUrl : blobUrl}
				onClick={() => {
					setVisibleImage(true);
				}}
				alt="description"
			/>
			{
				visibleImage && (

					<Lightbox
						image={fetching ? imageUrl : blobUrl}
						title="Image"
						onClose={() => {
							setVisibleImage(false);
						}}
					/>

				)
			}

		</div>
	);
};

export default ModalImageCors;
